.usecase-alt-header-button-one {
    height: 44px;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: black;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    background-color: #EEAC57;

    @media (max-width: 600px) {
        padding-right: 16px;
    }
}

.usecase-alt-header-button-two {
    height: 44px;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: black;
    cursor: pointer;
    color: white;
    border-radius: 4px;
    background-color: #0A3567;

    @media (max-width: 600px) {
        padding-right: 16px;
    }
}

.usecase-alt-heading {
    font-size: 94px;
}

.usecase-alt-heading-subtext {
    font-size: 24px;
    margin-top: 16px;
}

.usecase-alt-spacing {
    width: 16px;
}

.usecase-alt-card {
    box-shadow: 0px 3px 10px #00000012;
    height: 308px;
    width: 396px;
    max-width: 100%;
    min-width: 300px;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 32px;
    padding-bottom: 32px;
    background-color: white;
    border-radius: 20px;
}

.usecase-alt-carousel-button {
    height: 64px;
    width: 64px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EEAC57;
    cursor: pointer;
}

.usecase-alt-carousel-dot-spacing {
    width: 16px;
}

.usecase-alt-carousel-dot {
    height: 16px;
    width: 16px;
    border-radius: 16px;
}

.usecase-alt-input {
    height: 44px;
    border: solid 1px #D8D8D8;
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    font-size: 16px;
    resize: none;
}

.usecase-alt-button {
    height: 44px;
    border-radius: 4px;
    background-color: #EEAC57;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: white;
    font-weight: black;
    cursor: pointer;
    width: fit-content;
    box-sizing: border-box;
    padding-left: 35px;
    padding-right: 35px;
}

.disable-highlight::selection {
    background-color: transparent;
    color: #000;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.disable-highlight::-moz-selection {
    background-color: transparent;
    color: #000;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}