
@import "../../scss/variables";

.buttons {
    &-container {
        position: relative;
        bottom: 85px;
    }
    &-div {
        width:65vw;
        background-color:$white;
        display:inline-block;
        padding: 7px 65px;
        @media screen and (max-width:1150px) {
            width:85vw;
        }
        @media screen and (max-width:980px) {
            width:95vw;
        }
        @media screen and (max-width:860px) {
            width:100vw;
            padding: 0px;
        } 
    }
    &-selected {
        background-color:$orange;
        color: $white;
        padding: 5px 20px;
        border-radius: 20px;
        border: 1px solid $orange;
        margin: 20px 0px 20px 20px;
        font-weight: 500;
    }
    &-unselected {
        @extend .buttons-selected;
        background-color:$white !important;
        color: $primary !important;
        border: 1px solid $primary !important;
    }
}