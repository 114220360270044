.booking-form-hubspot {
  .submit-btn-booking {
    &:hover {
      transform: scale(1.07);
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

.textcolor{
  color: #589BEC;
}
.textMessage{
  color:#144989;
}
.textreport{
  color: #587393;
}
