.terms-and-conditions {
  li {
    display: block;
    background-image: url("../../../src/images/smart-building/check.svg");
    background-repeat: no-repeat;
    background-position: left top;
    // height: 50%;
    background-size: 1rem 3rem;
    padding-left: 44px;
    padding-top: 5px;
  }
}
