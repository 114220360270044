.allProjects-container .common-box {
  /* background-color: #2a70c4; */
  min-height: 355px;
  cursor: pointer;
}
/* .allProjects-container .common-box:hover {
  background-color: #194d8b;
} */
.allProjects-container .aderco_img_container img {
  height: 325px;
}
.allProjects-container .common-box p {
  opacity: 0.9;
  font-size: 14px;
  text-align: center;
}
.allProjects-container .common-box button:hover {
  background: rgba(0, 0, 0, 0.2);
}
.allProjects-container .common-box button {
  opacity: 0.9;
}
.allProjects-container .image-container {
  padding-bottom: 165px;
}
.allProjects-container .image-container img {
  height: 210px;
  margin: -30px 0px 0px 0px;
  position: absolute;
}
