.presentation-mode .my-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  //   height: 100vh;
  padding: 75px;
  line-height: 1.5;
  font-size: 1.5em;
  color: #f5f5f5;
}
// .presentation-mode .my-slide.primary {
//   background-color: #009;
// }
.carousel .control-dots .dot {
  width: 28px !important;
  height: 28px !important;
  box-shadow: none !important;
  background: #e8f3ff !important;
  opacity: 1 !important;
}

.carousel .control-dots .dot.selected {
  background: #144372 !important;
}

.logo {
  width: auto !important;
  height: auto !important;
}

.quotes {
  width: 50% !important;
}
