// contact_us_container
.contact_us_container {
  display: flex;
  justify-content: center;
  .common-text-color {
    color: #143255;
  }
  //   left_side_div
  .left_side_div {
    width: 25%;
    margin: 0px 5px;

    @media (max-width: 1024px) and (min-width: 320px) {
      width: 100%;
      margin: 0px;
    }
  }
  .left-right-title {
    color: #134989;
  }
  // Middle_div
  .Middle_div {
    width: 40%;
    margin: 0px 10px;
    .form_title {
      color: #eeac56;
    }
    .form_div {
      ::placeholder {
        color: #d7d7d7;
      }
      button {
        background-color: #eeac56;
        &:hover {
          background-color: #df9c46;
          transform: scale(1.07) !important;
        }
      }
    }
    @media (max-width: 1024px) and (min-width: 320px) {
      margin: 0px;
      width: 100%;
    }
  }
  // right_side_div
  .right_side_div {
    width: 25%;
    margin: 0px 5px;

    @media (max-width: 1024px) and (min-width: 320px) {
      width: 100%;
      margin: 0px;
    }
  }
  @media (max-width: 1024px) and (min-width: 320px) {
    flex-direction: column;
  }
  input {
    outline: none;
  }
  textarea {
    outline: none;
  }
}
