.main-kubernetes {
  .bg-light {
    background: #206ac2;
    border: 1px solid #206ac2;
  }
  .bg-dark {
    background: #144471;
    border: 1px solid #144471;
  }
  .bg-shared {
    background: #147165;
    border: 1px solid #147165;
  }
  .border-b {
    border-bottom: 1px solid #ffffff;
  }
  .border-t{
    border-top: 1px solid #ffffff;
  }
  .clr-blue {
    color: #144471;
  }
}

// Style for radio buttons

input[type="radio"] {
  display: none;
}

input[type="radio"] {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
  padding: 10px 0px;
}

input[type="radio"]:checked:after {
  border: solid 6px #144372;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  background-color: #eeac57;
  content: "";
}

input[type="radio"]:before {
  content: "";
  border: solid 6px #e4e4e4;
  width: 26px;
  position: absolute;
  left: -0.5px;
  bottom: -0.5px;
  height: 26px;
  border-radius: 50%;
}
