.gallery {
    &-container {
        width: 100%;
    }

    &-image {
        height: 338px;
        width: 338px;
        max-width: 100%;
        object-fit: contain;
        background-color: #206AC2;

        @media (max-width: 1440px) {
            height: 300px;
            width: 300px;
        }

        @media (max-width: 1024px) {
            height: 235px;
            width: 235px;
        }

        @media (max-width: 768px) {
            height: 175px;
            width: 175px;
        }

        @media (max-width: 600px) {
            height: 300px;
            width: 100%;
            margin-bottom: 10px;
        }
    }

    &-inner {
        &-container {
            flex-wrap: wrap;

            @media (max-width: 600px) {
                flex-wrap: wrap;
            }
        }
    }
}