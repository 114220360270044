@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "brandon-normal";
  src: local("brandon-normal"),
  url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
}
@font-face {
  font-family: "brandon-bold";
  src: local("brandon-bold"),
  url("https://use.typekit.net/af/257c86/000000000000000077359df6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
}
@font-face {
  font-family: "brandon-medium";
  src: local("brandon-medium"),
  url("https://use.typekit.net/af/d03e48/000000000000000077359df2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
}
