
.secondimage{
  margin-top: 7.5rem;
}
.centered {
  color: aqua;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.designImg{
  position: absolute;
    left: 16.5rem;
    margin-top: 4rem;
    height: 125px;
}

.accessibleImg{
  position: absolute;
  height: 125px;
  left: 31.5rem;
    margin-top: 11rem;
}

.incrementalImg{
  position: absolute;
  height: 125px;
  left: 44rem;
    margin-top: 4rem;
}

.dedicatedImg{
  position: absolute;
  height: 125px;
  left: 70%;
    margin-top: 7.5rem;
}

.divheight{
  height: 500px;
}

.photo{
  background-image: url("../../images/Polygone.svg");
}

.poligon {
  display: inline-block;
  position: relative;
  width: 275px;
  height: 275px;
  background: rgb(232, 243, 255);
  box-sizing: border-box;
  -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
}


.poligon:hover {
  background: rgb(240, 247, 255);
  -webkit-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
}

