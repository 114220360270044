.main-purpose-section {
  background-color: #e8f3ff;
  .fnt-black {
    font-family: "Brandon Grotesque black";
  }
  h1 {
    color: #134989;
  }
  .clr-for-text {
    color: #002f5a;
  }
  // .clr-for-bold-text {
  //   color: #002F5A;
  // }
}
