@media (min-width: 1024px) {
  .lg\:bg-forms-homepage-tri {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 85%;
  }
}
.lottieAnimationJoinUs {
  text-align: -webkit-center;
  @media (min-width: 1024px) {
    svg {
      width: 70% !important;
      height: 100% !important;
    }
  }
}
