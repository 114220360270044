.cta {
  &-container {
    height: 321px;
    width: 100%;
    background-color: #e8f3ff;

    @media (max-width: 768px) {
      height: auto;
    }
  }

  &-icon {
    // height: 170px;
    width: 215px;
    object-fit: contain;
    margin-right: 69px;
    display: flex;

    @media (max-width: 1024px) {
      margin-right: 50px;
      width: 190px;
    }

    @media (max-width: 768px) {
      margin-right: 0px;
      margin-bottom: 40px;
      margin-top: 40px;
    }
  }

  &-button {
    padding: 8px 40px;
    cursor: pointer;
    background-color: #eeac57;
    margin-top: 40px;

    @media (max-width: 768px) {
      margin-bottom: 40px;
    }
  }

  &-button:hover {
    transform: scale(1.07);
  }

  &-content {
    &-container {
      margin-left: -240px;
      display: flex;
      flex-direction: row;

      @media (max-width: 768px) {
        margin-left: 0px;
        flex-direction: column;
      }
    }
  }
}
