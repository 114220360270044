.main-team-section {
    img {
      height: 230px;
      width: 230px;
    }
    .member-name {
      color: #134989;
    }
  
    // .member-title {
    //   font-family: "Brandon Grotesque Light";
    // }
  }
  