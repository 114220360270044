@media (min-width: 1280px) {
  .xl\:bg-forms-homepage-tri {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 85%;
  }
}
@media (min-width: 1024px) {
  .lg\:bg-forms-homepage-tri {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 85%;
  }
}

.divHeight{
  max-height:520px;
}
