.partners-container {
  .react-slideshow-wrapper {
    div {
      display: flex;
      justify-content: center;
    }
  }
}

.border-bottom-white {
  border-bottom: 2px solid #fff;
}
.homeWork {
  &-bottom-triangle {
    transform: rotate(180deg) !important;
    position: relative;
    top: 3px;
  }
}
