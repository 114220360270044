.product-owner {
  h1 {
    color: #134989;
  }
  p {
    color: #283a50;
  }
  .bg-color-blue {
    background-color: #e8f3ff;
  }
  .apply-btn-owner {
    button {
      cursor: pointer;
      background-color: #eeac57;
      padding: 8px 40px;
      &:hover {
        background-color: #db881b;
        transform: scale(1.07);
      }
    }
  }
}
.join-us-form {
  //   position: fixed;
  //   right: 0;
  //   top: 20%;
  //   z-index: 6;
  .close-icon-lets-talk {
    display: flex;
    justify-content: end;
    img {
      height: 16px;
      cursor: pointer;
    }
  }
  .inner-form {
    padding: 20px;
    background: #f8aa41;
    border-radius: 6px;
    input {
      outline: none;
      width: 100%;
      border: none;
    }
    textarea {
      outline: none;
      border: none;
    }
  }
  .heading-lets-talk {
    color: #fff;
  }
  .btn-lets-talk {
    background-color: #002f5d;
    border: 1px solid #002f5d;
    cursor: pointer;
    &:hover {
      transform: scale(1.07);
    }
  }
  .btn-uploads {
    background-color: #f8aa41;
    border: 1px solid #fff;
    // &:hover {
    //   background-color: #002f5d;
    //   border: 1px solid #002f5d;
    //   cursor: pointer;
    // }
  }
  .clr-upload-file {
    color: #002f5d;
  }
}
