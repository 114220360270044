.join-us-main {
  .left-div {
    background-color: #206ac2;
    .left-div-p {
      color: #fff;
    }
  }
  .color-blue {
    color: #134989;
  }
  .color-para {
    color: #283a50;
  }
  .apply-btn {
    button {
      cursor: pointer;
      background-color: #eeac57;
      padding: 8px 40px;
      &:hover {
        background-color: #db881b;
        transform: scale(1.07);
      }
    }
  }
  .image-less-add {
    height: 20px;
    cursor: pointer;
  }
}
