.partners-container {
  .react-slideshow-wrapper {
    div {
      display: flex;
      justify-content: center;
      .logo-anchor {
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;

          &:first-child {
            display: flex;
          }

          &:nth-of-type(2) {
            position: relative;
            top: unset;
            left: unset;
            visibility: hidden;
          }

          &:last-child {
            display: none;
          }
        }
        &:hover {
          img {
            &:first-child {
              display: none;
            }
            &:last-child {
              display: flex;
            }
          }
        }
      }
    }
  }
}
