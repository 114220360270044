.bg-soustraction-img {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  @media (min-width: 1024px) {
    height: 570px;
  }
  @media screen and (max-width: 1000px) {
    height: 800px;
  }
  @media screen and (max-width: 500px) {
    height: 500px;
  }
}
@media (min-width: 1024px) {
  .xl\:bg-forms-homepage-tri {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 85%;
  }
  .lg\:bg-forms-homepage-tri {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 85%;
  }
}
