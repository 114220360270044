.work-with-us-main {
  .div-top {
    background-color: #206ac2;
    h1 {
      color: #fff;
    }
  }
  .vs-div {
    position: absolute;
    /* padding: 16px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -7px;
    height: 80px;
    left: 47.5%;

    width: 80px;
    border-radius: 50%;
    background: #eeac57;
    @media (max-width: 1366px) and (min-width: 1024px) {
      left: 47%;
    }
    h1 {
      color: #fff;
    }
  }
  p {
    color: #134989;
  }
}
@media (min-width: 1024px) {
  .lg\:bg-forms-homepage-tri {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 85%;
  }
}
.lottieAnimationWorkWithUs {
  text-align: -webkit-center;
  width: 70% !important;
  @media (min-width: 1024px) {
    svg {
      width: 70% !important;
      height: 100% !important;
    }
  }
}
