.textPrimaryDark {
  color: #124988;
}
.text_primary_light{
  color: #587692;
}
.text_primary_dark{
  color: #20496f;
}
.text_primary_dark_2{
  color: #163558;
}
.text_primary_light_2 {
  color: #5b748e;
}
.bg_primary_light{
  background-color: #1f6ac2;
}
