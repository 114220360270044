@import "../../scss/variables";
.work {
    &-container {
        margin-top: 50px;
        padding-bottom: 20px;
    }
    &-image {
        display: inline-block;
        margin-top: 60px;
        background-image: url("../../images/home/Ligne+point.svg");
        width: 70%;
        height: 50vh;
        background-repeat: no-repeat;
        background-size: contain;
    }
    &-button {
        margin-top: 30px;
        background-color:$lightBlue;
        color: $orange;
        padding: 11px 16px 8px 16px;
        border: 1px solid $orange;
        &:hover {
            background-color: $orange;
            color: $white; 
        }
    }
    &-content-container {
       position: relative;
    }
    &-heading-1 {
        margin-right: 25%;
        &-text {
            @extend .work-heading-1;
            margin-top: 20%;
            color: $text-color;
        }
    }
    &-heading-2 {
        @extend .work-heading-1;
        &-text {
            @extend .work-heading-2;
            margin-top: 20%;
            color: $text-color;
        }
    }
    &-heading-3 {
        position: relative;
        left: 43%;
        bottom: 43%;
        &-text {
            @extend .work-heading-3;
            bottom: 12%;
            color: $text-color;
        }
    }
    &-heading-4 {
        position: relative;
        left: 45%;
        top: -4%;
        &-text {
            @extend .work-heading-4;
            top: 15%;
            color: $text-color;
        }
    }
}
