.main-let-talk-button {
  .inner-col {
    .image-lets-talk {
      // width: 70px;
      position: fixed;
      right: 0;
      top: 20%;
      z-index: 6;
      cursor: pointer;
    }
    .lets-talk-form {
      position: fixed;
      max-height: calc(80vh - -20px);
      overflow: auto;
      right: 0;
      top: 20%;
      z-index: 6;
      .close-icon-lets-talk {
        display: flex;
        justify-content: end;
        img {
          height: 16px;
          cursor: pointer;
        }
      }
      form {
        padding: 20px;
        background: #f8aa41;
        border-radius: 6px;
        input {
          outline: none;
          border: none;
          width: 100%;
        }
        textarea {
          outline: none;
          border: none;
        }
      }
    }
  }
  .heading-lets-talk {
    color: #fff;
  }
  .btn-lets-talk {
    background-color: #002f5d;
    border: 1px solid #002f5d;
    cursor: pointer;
    &:hover {
      transform: scale(1.07);
    }
  }
}
