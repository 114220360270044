.company-description {
  .container {
    display: grid;
    grid-template-columns: minmax(10px, 400px) 1fr;
    grid-gap: 50px;
    max-width: 1400px;
    width: 100%;
    margin: auto;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }

  .heading {
    color: #134989;
    font-size: 28px;
    text-align: left;
  }

  .description {
    color: #134989;
    font-size: 16px;
  }

  .cards-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    .icon-with-content {
      // display: flex;
      // flex-direction: column;
      // justify-content: center;

      &:first-child {
        border-right: 1px solid #206ac2;
        border-bottom: 1px solid #206ac2;
      }

      &:nth-of-type(2) {
        border-bottom: 1px solid #206ac2;
      }

      &:nth-of-type(3) {
        border-right: 1px solid #206ac2;
      }

      @media (max-width: 600px) {
        border-bottom: 1px solid #206ac2;

        &:first-child {
          border-right: 0;
        }

        &:nth-of-type(2) {
          border-bottom: 1px solid #206ac2;
        }

        &:nth-of-type(3) {
          border-right: 0;
        }
      }
    }
  }
}
