.margin-top8Per {
  margin-top: -8%;
}
 
.bg-custom {
  background-color: #e8f3ff;
}
.clr-sub-title {
  color: #206ac2;
}
.image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.Design {
  margin-top: 8rem;
}
.Uiux {
  padding-top: 7rem;
}
.dev {
  margin-top: 11rem;
}
.Maintenance {
  margin-top: 12rem;
}

.cout3 {
  margin-top: 10rem;
}

.stickynav {
  position: sticky;
  top: 90px;
}

.resImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 300px;
  width: 100%;
}
