.home-projects-container {
  .absolue_img_container {
    margin-top: -470px;
    z-index: -1;
    width: 100%;
    @media (max-width: 2300px) and (min-width: 1300px) {
      margin-top: -530px;
    }
  }
  @media (min-width: 1280px) {
    .container {
      max-width: 1500px;
    }
  }
  .all_cards_container button:hover {
    background: rgba(0, 0, 0, 0.2);
  }
  .All_content_div {
    .heading_div {
      color: #002f5a;
      font-family: "Brandon Grotesque Black";
    }
    .text_main_div {
      font-family: "Brandon Grotesque Light";
      .text_div {
        color: #143255;
      }
    }
  }
  .inside_screen_image {
    margin: -22px 0px 0px 0px !important;
    height: 118px;
  }
  .h_155 {
    height: 155px !important;
  }
  .h_170 {
    height: 170px !important;
  }
  .h_180 {
    height: 180px !important;
  }
  .h_195 {
    height: 195px !important;
  }
  .h_100p {
    height: 100% !important;
  }
  .positions_container {
    min-height: 355px;
    @media (max-width: 1375px) and (min-width: 1024px) {
      min-height: 325px !important;
    }
    @media (max-width: 639) and (min-width: 320px) {
      min-height: 325px !important;
    }
    .image-container {
      img {
        margin: -30px 0px 0px 0px;
        position: absolute;
        z-index: 1;
      }
    }
    .title_container {
      padding-top: 150px;
    }
  }
  .button_div {
    button {
      color: #eeac57;
      border: 1px solid #eeac57;
      &:hover {
        background-color: #eeac57;
        color: #fff;
      }
    }
  }
  .outside_screen {
    transition: 0.5s ease-in-out;
    opacity: 1;
  }
  .hover_box {
    .hover_image_container {
      opacity: 0;
      transition: 0.5s ease-in-out;
      img {
        position: absolute;
        height: 240px;
        display: none;
        @media (max-width: 1375px) and (min-width: 1024px) {
          height: 185px !important;
        }
        @media (max-width: 639px) and (min-width: 320px) {
          height: 185px !important;
        }
      }
    }
    .box-one {
      img {
        height: 195px !important;
        @media (max-width: 1375px) and (min-width: 1024px) {
          height: 140px !important;
        }
        @media (max-width: 639px) and (min-width: 320px) {
          height: 140px !important;
        }
      }
    }
    .aderco-box-gif {
      display: none;
    }
    &:hover {
      .aderco-content {
        display: none;
      }
      .content_btn_container {
        p {
          transition: 0.5s ease-in-out;
          opacity: 0;
        }
      }
      .aderco-content {
        display: none;
      }
      .aderco-box {
        display: none;
      }
      .aderco-box-gif {
        display: block;
      }
      .outside_screen {
        transition: 0.5s ease-in-out;
        opacity: 0;
      }
      .content_btn_container {
        display: none;
      }
      .title_container {
        display: none;
      }
      .hover_image_container {
        transition: 0.5s ease-in-out;
        opacity: 1;
        img {
          display: block;
        }
      }
      .button_container {
        padding-top: 260px !important;
        @media (max-width: 639px) and (min-width: 320px) {
          padding-top: 230px !important;
        }
        @media (max-width: 1375px) and (min-width: 1024px) {
          padding-top: 230px !important;
        }
      }
    }
  }
}
