@media (min-width: 1024px) {
  .lg\:bg-forms-homepage-tri {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 85%;
  }
}
.lottieAnimationContact {
  @media (min-width: 1024px) {
    svg {
      width: 500px !important;
      height: 400px !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
