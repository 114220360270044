.main-animation-bird {
  .css-1qk1t6n {
    position: unset !important;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    opacity: 0;
    cursor: unset !important;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }
  .css-18wbp41 {
    display: none !important;
  }
  .css-djit9u {
    width: 100% !important;
  }
  .css-t2klrc {
    width: 100% !important;
    height: 100% !important;
  }
}
